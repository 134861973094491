import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context";
import {
  ColorCard,
  Dnd,
  FilterByBrand,
  ModalWindow,
  ViewDetailsRow,
} from "../index";
import {
  colorMatchFn,
  sizes,
  useBalloonsV2,
  useFilterByBrand,
  useScreenSize,
} from "../../utils";
import style from "./CardsContainer.module.css";

const CardsContainer = ({ sortable, setSelectedColor }) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { colormatch, setColormatch } = useAppContext();
  const { data: balloonsData, isLoading } = useBalloonsV2();
  const [modal, setModal] = useState({
    show: false,
    color: {},
  });
  const {
    closerColors,
    secondaryMatches,
    thirdMatchRange,
    fourthMatchRange,
    fifthMatchRange,
    ...colorPicked
  } = modal.color;
  //const { brandsSelected, filteredBrands, handleBrandsChange } = useFilterByBrand(closerColors);

  let allCloserColors = [
    ...(Array.isArray(closerColors) ? closerColors : []),
    ...(Array.isArray(secondaryMatches) ? secondaryMatches : []),
    ...(Array.isArray(thirdMatchRange) ? thirdMatchRange : []),
    ...(Array.isArray(fourthMatchRange) ? fourthMatchRange : []),
    ...(Array.isArray(fifthMatchRange) ? fifthMatchRange : []),
  ];

  const {
    brandsSelected: brandsSelectedCloser,
    filteredBrands: filteredBrandsCloser,
    handleBrandsChange: handleBrandsChangeCloser,
  } = useFilterByBrand(allCloserColors);
  //const { brandsSelected: brandsSelectedSecondary, filteredBrands: filteredBrandsSecondary, handleBrandsChange: handleBrandsChangeSecondary } = useFilterByBrand(secondaryMatches);

  let listOfCloserColors;
  if (filteredBrandsCloser) {
    listOfCloserColors = [colorPicked, ...filteredBrandsCloser];
  }

  /* let listOfSecondaryColors;
  if (filteredBrandsSecondary) {
    listOfSecondaryColors = [...filteredBrandsSecondary];
  } */

  const handleColorMatch = useCallback(
    (elementSelected, index) => {
      colorMatchFn(
        balloonsData,
        isLoading,
        colormatch,
        setColormatch,
        elementSelected,
        index
      );
    },
    [balloonsData, isLoading, colormatch, setColormatch]
  );

  const hideModal = useCallback(() => {
    setModal((prevModal) => ({ ...prevModal, show: false }));
  }, []);

  const replaceColors = useCallback(
    (color) => {
      const index = colormatch.findIndex(
        (item) => item.color === colorPicked.color
      );
      handleColorMatch(color.color, { insert: index });
      hideModal();
    },
    [colormatch, colorPicked.color, handleColorMatch, hideModal]
  );

  const addColor = useCallback(
    (color) => {
      handleColorMatch(color.color);
      hideModal();
    },
    [handleColorMatch, hideModal]
  );

  const renderCloserColors = () => {
    if (!colorPicked) return null;
    if (!filteredBrandsCloser || filteredBrandsCloser.length === 0) {
      return (
        <>
          <h4 className={style.listColorTitle}>Selected:</h4>
          <ViewDetailsRow
            index={0}
            key={colorPicked.color}
            item={colorPicked}
            sizes={sizes}
            addColor={addColor}
            replaceColors={replaceColors}
            buttons={true}
            closer={true}
          />
          {filteredBrandsCloser?.length === 0 && (
            <Col xs={12} className="d-flex justify-content-end">
              <FilterByBrand
                brandsSelected={brandsSelectedCloser}
                handleChange={handleBrandsChangeCloser}
                viewDetailsModal={true}
              />
            </Col>
          )}
          <p className={style.noBalloons}>
            No match available for the selected brand(s)
          </p>
        </>
      );
    }

    return (
      <>
        <h4 className={style.listColorTitle}>Selected:</h4>
        {listOfCloserColors.map((item, index) => (
          <>
            {index === 0 && (
              <ViewDetailsRow
                index={0}
                key={colorPicked.color}
                item={colorPicked}
                sizes={sizes}
                addColor={addColor}
                replaceColors={replaceColors}
                buttons={true}
                closer={true}
              />
            )}
            {index === 1 && (
              <>
                <h1>{filteredBrandsCloser.length}</h1>
                <Col xs={12} className="d-flex justify-content-end">
                  <FilterByBrand
                    brandsSelected={brandsSelectedCloser}
                    handleChange={handleBrandsChangeCloser}
                    viewDetailsModal={true}
                  />
                </Col>
                <h4 className={style.listColorTitle}>Backup options:</h4>
                {filteredBrandsCloser.length < 1 && (
                  <p className={style.noBalloons}>
                    No match available for the selected brand(s)
                  </p>
                )}
              </>
            )}
            {item.color !== colorPicked.color && (
              <ViewDetailsRow
                index={index}
                key={item.color}
                item={item}
                sizes={sizes}
                addColor={addColor}
                replaceColors={replaceColors}
                buttons={true}
                closer={true}
              />
            )}
            {/* {listOfCloserColors.length === 0 &&
              <p className={style.noBalloons}>
                No matches found. There are no items available that meet the
                selected filters or color criteria at this time
              </p>
            } */}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Row
        id="cardsContainer"
        xs={3}
        md={3}
        lg={4}
        xl={4}
        className="row-gap-4"
      >
        {sortable ? (
          <Dnd data={colormatch} setData={setColormatch}>
            {colormatch.map((color, index) => (
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
              />
            ))}
          </Dnd>
        ) : (
          colormatch.map((color, index) => (
            <ColorCard
              key={index}
              color={color}
              modal={modal}
              setModal={setModal}
              sortable={sortable}
              setSelectedColor={setSelectedColor}
            />
          ))
        )}
      </Row>
      <ModalWindow
        show={modal.show}
        onHide={hideModal}
        closeButton={true}
        centered={true}
        size="xl"
        fullscreen={screenSize < 992 && true}
        viewDetailsModal={true}
        /* header={
          <Row>
            <Col className="mb-2" xs={12}>
              <p className={style.title}>
                {t(
                  "Color swatches are presented from the closest match to the least."
                )}
              </p>
            </Col>
            <Col xs={12}>
              <FilterByBrand
                brandsSelected={brandsSelectedCloser}
                handleChange={handleBrandsChangeCloser}
              />
            </Col>
          </Row>
        } */
        body={
          <Row>
            <Col className={`p-0 ${style.infoContainer}`}>
              {renderCloserColors()}
              {/* {secondaryMatches && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>2-5</p>
                </>
              )}
              {secondaryMatches?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  index={index}
                  sizes={sizes}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {thirdMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>5-10</p>
                </>
              )}
              {thirdMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  index={index}
                  sizes={sizes}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {fourthMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>10-15</p>
                </>
              )}
              {fourthMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  index={index}
                  sizes={sizes}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))}

              {fifthMatchRange && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>15-20</p>
                </>
              )}
              {fifthMatchRange?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  index={index}
                  sizes={sizes}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={true}
                />
              ))} */}
            </Col>
          </Row>
        }
      />
      {screenSize <= 992 && (
        <Row>
          <Col className="justify-content-end d-flex p-4">
            <p className={style.versionTxt}>
              Beta version 1.1.10. {t("versionTxt")}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardsContainer;

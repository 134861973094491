import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { RiUploadCloudLine } from "react-icons/ri";
import { ListGroup } from "react-bootstrap";
import { BottomSheet } from "../../index";
import style from "./MobileCommands.module.css";
import { FiDownload, FiShare } from "react-icons/fi";
import { addDropdownOptions } from "../../../utils";
import { useAppContext } from "../../../context";

const Add = ({ showBottomSheet, setShowBottomSheet, handleOptionClick }) => {
  const { t } = useTranslation();
  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          add: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item className={style.mainOption}>
          <AiOutlineFolderOpen size="1.5rem" /> Add to Palette from Library...
        </ListGroup.Item>
        {addDropdownOptions.map((option, index) => (
          <ListGroup.Item
            key={index}
            action
            onClick={() => {
              handleOptionClick(option.key, option.label);
              setShowBottomSheet((prevState) => ({
                ...prevState,
                add: false,
              }));
            }}
            className={
              option.label === "Add your own" ? style.mainOption : style.options
            }
          >
            {option.label === "Add your own" && (
              <RiUploadCloudLine size="1.5rem" />
            )}{" "}
            {t(option.label)}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </BottomSheet>
  );
};

const More = ({ showBottomSheet, setShowBottomSheet, setShow }) => {
  const { colormatch } = useAppContext();
  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          more: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item
          action
          disabled={colormatch.length < 2}
          className={style.mainOption}
          onClick={() => {
            setShow({ download: true });
            setShowBottomSheet((prevState) => ({
              ...prevState,
              more: false,
            }));
          }}
        >
          <FiDownload size="1.5rem" /> Download
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={colormatch.length < 2}
          className={style.mainOption}
          onClick={() => {
            setShow({ share: true });
            setShowBottomSheet((prevState) => ({
              ...prevState,
              more: false,
            }));
          }}
        >
          <FiShare size="1.5rem" />
          Share
        </ListGroup.Item>
      </ListGroup>
    </BottomSheet>
  );
};

const NavBottomSheets = {
  Add,
  More,
};

export default NavBottomSheets;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { sizes } from "../../utils";
import style from "./Filters.module.css";

const FilterBySize = ({ sizesSelected, handleChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const handleApplyFilters=()=>{
    setShowDropdown(false);
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  return (
    <Col className="mb-3 p-0" xs={6} sm={4} md={3} xl={2}>
      <DropdownBtn title={t("Filter by Size")} variant="secondary" filterDropdownBtn={true} showDropdown={showDropdown} handleApplyFilters={handleApplyFilters} toggleDropdown={toggleDropdown}>
        {sizes.map((size, index) => {
          const transformedSize = size.toString().replace(/\./g, "_");

          return (
            <Dropdown.Item
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(`size${transformedSize}`);
              }}
            >
              <Form.Check
                type="checkbox"
                checked={sizesSelected[`size${transformedSize}`]}
                name={`size${size}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(`size${transformedSize}`);
                }}
                onChange={() => {}}
                label={
                  transformedSize.includes("_1")
                    ? `${transformedSize.split("_")[0]}" LINK`
                    : size === 160
                    ? size
                    : size === 260
                    ? size
                    : size === 350
                    ? size
                    : size === 360
                    ? size
                    : `${size}"`
                }
              />
            </Dropdown.Item>
          );
        })}
        <div className="d-flex justify-content-end px-2">
          <Button className={`px-4 ${style.applyFiltersBtn}`} onClick={handleApplyFilters}>Apply</Button>
        </div>
      </DropdownBtn>
    </Col>
  );
};

export default FilterBySize;

import React from "react";
import { FaUser } from "react-icons/fa";
import { IoBalloon } from "react-icons/io5";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useAppContext } from "../../context";
import { NavLinkWithLocale } from "../../components";
import style from "./Dashboard.module.css";

const Dashboard = () => {
  const { user } = useAppContext();

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>
            Hi, {user ? user.name : <Spinner animation="grow" size="sm" />}
          </h1>
        </Col>
      </Row>
      <Row className={`gap-5 ${style.body} align-items-center`}>
        <Col className={style.cardContainer}>
          <Card>
            <Card.Body>
              <Card.Title>
                <FaUser size={25} />
              </Card.Title>
              <Card.Text>Would you like to manage users?</Card.Text>
              <NavLinkWithLocale to="users">Manage Users</NavLinkWithLocale>
            </Card.Body>
          </Card>
        </Col>
        <Col className={style.cardContainer}>
          <Card>
            <Card.Body>
              <Card.Title>
                <IoBalloon size={25} />
              </Card.Title>
              <Card.Text>Would you like to manage balloons?</Card.Text>
              <NavLinkWithLocale to="balloons">
                Manage Balloons database
              </NavLinkWithLocale>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;

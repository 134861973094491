import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { ExportBtn, Inputs } from "../../index";
import style from "./Download.module.css";

const DownloadContent = ({ window }) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [paletteName, setPaletteName] = useState("");
  const [options, setOptions] = useState({
    image: false,
    export: null,
  });

  useEffect(() => {
    if (!window) {
      setOptions((prevState) => ({
        ...prevState,
        export: null,
      }));
      setPaletteName("");
    }
  }, [window, setOptions]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPaletteName(value);
  };

  return (
    <Row className="justify-content-center">
      <Col xs={12}>
        <Form.Check
          className={style.optionsLabel}
          id="client view"
          label={<p>{t("For Client")}</p>}
          name="group1"
          type="radio"
          onChange={() => setOptions({ export: "client" })}
          checked={options.export === "client"}
        />
        <Form.Check
          className={style.optionsLabel}
          id="internal view"
          label={<p>{t("For Balloon Artist")}</p>}
          name="group1"
          type="radio"
          onChange={() => setOptions({ export: "internal" })}
          checked={options.export === "internal"}
        />
      </Col>
      
      <Inputs.FormControl
        label={t("Palette/Event Name:")}
        name="paletteName"
        value={paletteName}
        onChange={handleInputChange}
        type="text"
        placeholder={t("Alana's Baby Shower")}
        className={`${style.downloadTextInputs} mb-3`}
      />
     
      <Col xs={12}>
        <Form.Check
          className={style.optionsLabel}
          name="image"
          checked={options.image}
          inline
          id="Include Inspiration Image"
          label={t("Include Inspiration Images")}
          type="checkbox"
          onChange={() =>
            setOptions((prevState) => ({
              ...prevState,
              image: !prevState.image,
            }))
          }
        />
      </Col>
      <Col xs={12} className="mt-3">
        <ExportBtn
          image={options.image}
          exportOption={options.export}
          user={user}
          paletteName={paletteName}
        />
      </Col>
    </Row>
  );
};

export default DownloadContent;

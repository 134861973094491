import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import { DownloadBtn, SaveBtn, ShareBtn } from "../index";
import style from "./Commands.module.css";

const CardsCommands = () => {
  const { colormatch, images, setCreateNewPaletteModal } = useAppContext();
  const { t } = useTranslation();
  const [overlay, setOverlay] = useState({
    save: false,
    download: false,
    share: false,
  });

  const handleCreateNewPalette = () => {
    setCreateNewPaletteModal(true);
  };

  return (
    <Col
      xs={{ order: 2, span: 12 }}
      lg={{ order: 1, span: 6 }}
      className={`${style.cardsCommandsContainer}`}
    >
      <Row
        className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}
      >
        {/* <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <AddButton />
        </Col> */}
        {/* <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Button
            className={`${style.actionBtn} ${sortable && style.blackBtn}`}
            onClick={() => setSortable(!sortable)}
            variant="secondary"
            disabled={colormatch.length > 1 ? false : true}
          >
            {sortable ? null : <IoMdMove />}
            {sortable ? <span>{t("Done")}</span> : <span> {t("Reorder")}</span>}
          </Button>
        </Col> */}
        {/* <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Save />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol} ${style.shareBtn}`}>
          <Share />
        </Col> */}
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <SaveBtn overlay={overlay.save} setOverlay={setOverlay} />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <DownloadBtn overlay={overlay.download} setOverlay={setOverlay} />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <ShareBtn overlay={overlay.share} setOverlay={setOverlay} share />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Button
            variant="secondary"
            onClick={handleCreateNewPalette}
            size="sm"
            className={style.actionBtn}
            disabled={colormatch.length === 0 && images.length === 0}
          >
            <FiPlusCircle />
            {t("New Palette")}
          </Button>
        </Col>
      </Row>
      {(overlay.share || overlay.save || overlay.download) && (
        <div className={style.overlay} />
      )}
    </Col>
  );
};

export default CardsCommands;

import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiDeleteBack2Line, RiFolderSharedLine } from "react-icons/ri";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { app } from "../../../firebase";
import { useAppContext } from "../../../context";
import { Inputs } from "../../index";
import {
  Alert,
  imagesMapToUpload,
  useAddPalette,
  useDeleteUploadedImagesStorage,
  usePaletteById,
  useUpdatePalette,
} from "../../../utils";
import style from "./Save.module.css";

const db = getFirestore(app);

const SaveContent = ({ window, setSavedPalette }) => {
  const { t } = useTranslation();
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [paletteName, setPaletteName] = useState("");
  const [saveNew, setSaveNew] = useState(false);
  const { mutate: addPalette, isLoading } = useAddPalette(false);
  const updatePalette = useUpdatePalette();
  const [loading, setLoading] = useState(false);
  const { mutate: deleteImagesFromStorage } = useDeleteUploadedImagesStorage();
  const [indexToDelete, setIndexToDelete] = useState([]);
  const { paletteId } = useParams();
  const { data: palette, isSuccess } = usePaletteById(paletteId, false, {
    enabled: !!paletteId,
  });

  useEffect(() => {
    if (!window) {
      setSavedPalette(false);
    }
  }, [window, setSavedPalette]);

  const convertURLToFile = async (imageURL, imageName) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    const file = new File([blob], imageName, { type: blob.type });
    return file;
  };

  useEffect(() => {
    const paletteFiles = async () => {
      if (paletteId && isSuccess) {
        setPaletteName(palette.name);

        const dbImages = await Promise.all(
          palette.images.map(async (image) => {
            const { imageURL, imageName } = image;
            return await convertURLToFile(imageURL, imageName);
          })
        );

        const indexToDelete = dbImages
          .map((image1, index) =>
            !images.some(
              (image2) =>
                image1.name === image2.name && image1.size === image2.size
            )
              ? index
              : -1
          )
          .filter((index) => index !== -1);
        setIndexToDelete(indexToDelete);
      }
    };
    paletteFiles();
  }, [paletteId, palette, isSuccess, images]);

  const preparePaletteToSave = useCallback(
    async (paletteName, id) => {
      const imagesWithURLs = await imagesMapToUpload({
        images,
        setProgress,
        collection: "savedPalettes",
        id,
      });
      return {
        images: imagesWithURLs,
        colors: colormatch,
        user: user.email,
        name: paletteName,
        createdAt: serverTimestamp(),
      };
    },
    [colormatch, images, user.email]
  );

  const handleSavePalette = useCallback(async () => {
    setLoading(true);
    try {
      let newDocRef = doc(collection(db, "palettes"));
      const q = query(
        collection(db, "savedPalettes"),
        where("user", "==", user.email),
        where("name", "==", paletteName)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        throw new Error("There is already a palette with this name");
      }
      // Si no existe una paleta con el mismo nombre, proceder con la subida de imágenes
      const paletteToSave = await preparePaletteToSave(
        paletteName,
        newDocRef.id
      );

      addPalette({ palette: paletteToSave, id: newDocRef.id });

      Alert.success(
        `Palette saved. View it under 'My Palettes' in your profile.`
      );
    } catch (error) {
      Alert.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      setSavedPalette(true);
    }
  }, [
    user?.email,
    paletteName,
    preparePaletteToSave,
    addPalette,
    setSavedPalette,
  ]);

  const handleEditPalette = useCallback(async () => {
    setLoading(true);
    try {
      if (!paletteId) return;

      const paletteToSave = await preparePaletteToSave(paletteName, paletteId);

      const imagePathsToDelete = ({ images }) => {
        if (Array.isArray(images)) {
          return images.map((image) => {
            return deleteImagesFromStorage({
              imagePath: image.imagePath,
              imageURL: image.imageURL,
            });
          });
        } else if (typeof images === "string") {
          return deleteImagesFromStorage({
            imageURL: images,
          });
        }
      };

      const imagesToDelete = indexToDelete.map(
        (index) => palette.images[index]
      );
      imagePathsToDelete({
        images: imagesToDelete,
      });
      updatePalette.mutate({
        palette: paletteToSave,
        paletteId: paletteId,
      });
      Alert.success(
        `Palette updated. View it under 'My Palettes' in your profile.`
      );
    } catch (error) {
      Alert.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      setSavedPalette(true);
    }
  }, [
    deleteImagesFromStorage,
    preparePaletteToSave,
    indexToDelete,
    palette?.images,
    paletteId,
    paletteName,
    setSavedPalette,
    updatePalette,
  ]);

  console.log(paletteName);

  return saveNew || !paletteId ? (
    <Row className="justify-content-between align-items-end">
      <Col xs={8}>
        <Inputs.FormControl
          label={saveNew ? "Save as... " : "Palette / Event Name:"}
          placeholder="Alana’s Shower"
          value={paletteName}
          name="paletteName"
          onChange={(e) => setPaletteName(e.target.value)}
          size="sm"
          customClass={saveNew ? style.saveAsLabel : style.inputLabel}
        />
      </Col>
      <Col xs={4} className={style.saveBtnCol}>
        {saveNew && (
          <Button className={style.returnBtn} onClick={() => setSaveNew(false)}>
            <RiDeleteBack2Line />
          </Button>
        )}
        <Button
          variant="dark"
          onClick={handleSavePalette}
          className={style.saveBtn}
          disabled={paletteName === ""}
        >
          {loading || isLoading ? (
            "Saving..."
          ) : (
            <>
              <RiFolderSharedLine />
              {t("Save")}
            </>
          )}
        </Button>
      </Col>
    </Row>
  ) : (
    <Row className="justify-content-between align-items-end">
      <Col xs={12}>
        <Button
          variant="light"
          onClick={handleEditPalette}
          as="li"
          className={style.saveOption}
          style={{ borderBottom: "1px solid #d9d9d9" }}
        >
          {loading || isLoading ? (
            <Row className="justify-content-between">
              <Col xs="auto">{t("Saving Changes...")}</Col>
              <Col xs="auto">
                {" "}
                <Spinner animation="border" size="sm" />
              </Col>
            </Row>
          ) : (
            `${t("Save Changes")}`
          )}
        </Button>
      </Col>
      <Col xs={12}>
        <Button
          variant="light"
          onClick={() => {
            setSaveNew(true);
            setPaletteName("");
          }}
          as="li"
          className={style.saveOption}
        >
          {t("Save as new")}
        </Button>
      </Col>
    </Row>
  );
};

export default SaveContent;

import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoLink } from "react-icons/go";
import { FiSend } from "react-icons/fi";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { app } from "../../../firebase";
import { useAppContext } from "../../../context";
import { Inputs, NavLinkWithLocale } from "../../index";
import { Alert, imagesMapToUpload, useAddPalette } from "../../../utils";
import style from "./Share.module.css";
import { FaFacebookMessenger } from "react-icons/fa";

const db = getFirestore(app);

const ShareContent = ({
  window: popUp,
  options,
  setOptions,
  sharedPalette,
}) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { colormatch, images, user } = useAppContext();
  const { mutate: addPalette, isLoading, isSuccess } = useAddPalette(true);
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [paletteName, setPaletteName] = useState("");
  const [paletteId, setPaletteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!popUp) {
      setOptions({ image: false, export: null });
      setPaletteName("");
    }
    if (sharedPalette) {
      setPaletteId(location.pathname.split("/").at(-1));
    }
  }, [popUp, setOptions, location.pathname, sharedPalette]);

  useEffect(() => {
    // Si no es el primer render, actualiza dataChange
    if (!isInitialMount.current) {
      setDataChange(true);
      setPaletteId(null);
    } else {
      // Después del primer render, cambia el valor del re
      isInitialMount.current = false;
    }
  }, [colormatch, images, options]);

  const handleSharePalette = useCallback(() => {
    const newDocRef = doc(collection(db, "sharedPalettes"));
    const newId = newDocRef.id;
    return new Promise((resolve, reject) => {
      imagesMapToUpload({
        images,
        setProgress,
        collection: "sharedPalettes",
        id: newId,
      })
        .then((imagesWithURL) => {
          const paletteToSave = {
            images: imagesWithURL,
            colors: colormatch,
            showImage: options.image,
            type: options.export,
            createdAt: serverTimestamp(),
            user: user,
            paletteName: paletteName,
          };

          return addPalette(
            { palette: paletteToSave, id: newId },
            {
              onSuccess: (docId) => {
                setPaletteId(docId);
                resolve(docId);
              },
              onError: (error) => {
                Alert.error("Error sharing palette, try again.");
                reject(error);
              },
            }
          );
        })
        .catch((error) => {
          Alert.error("Error processing images, try again.");
          reject(error);
        })
        .finally(() => {});
    });
  }, [
    addPalette,
    options.export,
    options.image,
    paletteName,
    colormatch,
    images,
    user,
  ]);

  const copyLinkOfSavedPalette = useCallback(async () => {
    setLoading(true);
    try {
      let url = "";
      if (sharedPalette) {
        url = `${window.location?.protocol}//${window.location?.host}${location.pathname}`;
      } else {
        let id = paletteId;
        if (dataChange) {
          console.log("entra")
          setDataChange(false);
          id = await handleSharePalette();
        }
        if (id && !loading) {
          url = `https://colormatch.tech/${i18n.language}/share/${id}`;
        }
      }

      // Copiar al portapapeles si tenemos una URL
      if (url) {
        await navigator.clipboard.writeText(url);
        if (sharedPalette) {
          Alert.success(`Link Copied!`);
        } else {
          Alert.success(`Link Copied! The link will expire in 7 days`);
        }
      }
      setLoading(false);
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000);
    } catch (error) {
      console.log("Error al copiar el enlace: ", error);
    }
  }, [
    paletteId,
    dataChange,
    loading,
    handleSharePalette,
    sharedPalette,
    i18n.language,
    location?.pathname,
  ]);

  const handleCopyLink = () => {
    const url = `${window.location?.protocol}/${window.location?.host}${location.pathname}`;
    if (url) {
      navigator.clipboard.writeText(url);
      Alert.success("Link copied!");
      setLinkCopied(true);
      console.log("link copied true");

      // Restablecer el estado después de 3 segundos
      setTimeout(() => setLinkCopied(false), 4000);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPaletteName(value);
  };

  const media = [
    {
      id: "email",
      Component: EmailShareButton,
      Icon: EmailIcon,
    },
    {
      id: "whatsapp",
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
    },
    {
      id: "messenger",
      Component: FacebookMessengerShareButton,
      Icon: FaFacebookMessenger,
      extraProps: { appId: "521270401588372" },
      iconClassName: style.mediaIcon,
    },
    {
      id: "facebook",
      Component: FacebookShareButton,
      Icon: FacebookIcon,
    },
    {
      id: "twitter",
      Component: TwitterShareButton,
      Icon: XIcon,
      extraProps: { title: "Balloon Color Match" },
    },
  ];

  return (
    <>
      {!sharedPalette && (
        <Row>
          <Col xs={12}>
            <Form.Check
              className={style.optionsLabel}
              id="client view"
              label={<p>{t("Share Palette with Client")}</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "client",
                }))
              }
              checked={options.export === "client"}
            />
            <Form.Check
              className={style.optionsLabel}
              id="internal view"
              label={<p>{t("Share Palette with Balloon Artist")}</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "internal",
                }))
              }
              checked={options.export === "internal"}
            />
          </Col>
          <Inputs.FormControl
            label={t("Palette/Event Name:")}
            name="paletteName"
            value={paletteName}
            onChange={handleInputChange}
            type="text"
            placeholder={t("Alana's Baby Shower")}
            className={`${style.shareTextInputs} mb-3`}
          />
          <Col xs={12} className="mb-2">
            <Form.Check
              className={style.optionsLabel}
              name="image"
              checked={options.image}
              inline
              id="Include Inspiration Image"
              label={t("Include Inspiration Images")}
              type="checkbox"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  image: !prevState.image,
                }))
              }
            />
          </Col>
        </Row>
      )}
      <Row style={{ minWidth: 250 }}>
        <Col xs={6}>
          <Button
            variant="dark"
            className={`p-2 ${style.shareBtn}`}
            onClick={sharedPalette ? handleCopyLink : copyLinkOfSavedPalette}
            disabled={
              (!sharedPalette && !options.export) || isLoading || loading
            }
          >
            {loading || isLoading ? (
              t("Generating...")
            ) : isSuccess || linkCopied ? (
              <>{t("Link Copied!")}</>
            ) : (
              <>
                <GoLink /> {t("Copy Link")}
              </>
            )}
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            variant="dark"
            className={`p-2 ${style.shareBtn}`}
            onClick={() => {
              setShowMedia(!showMedia);
              !sharedPalette && handleSharePalette();
            }}
            disabled={
              (!sharedPalette && !options.export) || isLoading || loading
            }
          >
            <FiSend /> Share
          </Button>
        </Col>
      </Row>
      {showMedia && paletteId && (
        <Row className={`mt-3 justify-content-around ${style.mediaContainer}`}>
          {media.map(({ id, Component, Icon, extraProps, iconClassName }) => (
            <Col key={id} xs="auto">
              <Component
                url={`https://colormatch.tech/${i18n.language}/share/${paletteId}`}
                {...extraProps}
              >
                <Icon size="1.5rem" round className={iconClassName || ""} />
              </Component>
            </Col>
          ))}
        </Row>
      )}
      {paletteId && !sharedPalette && (
        <Col className="mt-2" xs="auto">
          <NavLinkWithLocale
            target="_blank"
            to={`/share/${paletteId}`}
            className={style.optionsSubLabel}
          >
            {`https://colormatch.tech/${i18n.language}/share/${paletteId}`}
          </NavLinkWithLocale>
        </Col>
      )}
    </>
  );
};

export default ShareContent;

import React from "react";
//import { NavLink } from "react-router-dom";
//import { GrUserSettings } from "react-icons/gr";
import { FaSignOutAlt } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
//import { MdFavoriteBorder } from "react-icons/md";
import { RiFolderLine } from "react-icons/ri";
import {
  Button,
  Col,
  Nav,
  NavDropdown,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import style from "./HeaderBar.module.css";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher, NavLinkWithLocale } from "../index";

const DesktopMenu = ({
  user,
  loading,
  handleChangeLocation,
  handleLogOut,
  actualLocation,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Offcanvas.Body>
      <Nav className={style.offcanvasNav}>
        <Row
          className={`p-0 m-0 justify-content-between align-items-center ${style.offcanvasNav}`}
        >
          <Col className={`m-0 ${style.productLink}`} md={2}>
            <NavLinkWithLocale className={`${style.links}`} to="/product">
              {t("Pricing")}
            </NavLinkWithLocale>
          </Col>
          <Col className="p-0 m-0" md={10}>
            <Row className={`p-0 m-0 align-items-center justify-content-end`}>
              {loading ? (
                <Col md={2}>
                  <Spinner animation="grow" size="sm" variant="dark" />
                </Col>
              ) : user ? (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <Button
                      variant="secondary"
                      className={style.links}
                      onClick={handleChangeLocation}
                    >
                      {actualLocation === `/${i18n.language}/colormatch`
                        ? t("Create New Palette")
                        : t("My Palette")}
                    </Button>
                  </Col>
                  {user.role === "1" && (
                    <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                      <NavLinkWithLocale
                        className={style.links}
                        to="/dashboard"
                      >
                        Dashboard
                      </NavLinkWithLocale>
                    </Col>
                  )}
                  <Col className="p-0 m-0" md="auto">
                    <NavDropdown
                      title={user.name}
                      className={style.links}
                      align={{ lg: "end" }}
                    >
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/profile"
                        className={style.userOptions}
                      >
                        <IoSettingsOutline />
                        {t("Account Settings")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/profile/palettes"
                        className={style.userOptions}
                      >
                        <RiFolderLine />
                        {t("Saved palettes")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={handleLogOut}
                        className={style.userOptions}
                      >
                        <FaSignOutAlt size="1rem" />
                        {t("Log out")}
                      </NavDropdown.Item>
                      <NavDropdown.Divider className={style.dropdownDivider} />
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/terms"
                        className={style.sublinks}
                      >
                        {t("Terms")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/privacyPolicy"
                        className={style.sublinks}
                      >
                        {t("Privacy Policy")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLinkWithLocale}
                        to="/contactUs"
                        className={style.sublinks}
                      >
                        {t("Contact Us")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Col>
                </>
              ) : (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLinkWithLocale
                      className={style.links}
                      to="/createAccount"
                    >
                      {t("Sign Up")}
                    </NavLinkWithLocale>
                  </Col>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLinkWithLocale className={style.links} to="/login">
                      {t("Log In")}
                    </NavLinkWithLocale>
                  </Col>
                </>
              )}
              <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                <LanguageSwitcher drop="down" position="header" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Nav>
    </Offcanvas.Body>
  );
};

export default DesktopMenu;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { MdDone, MdOutlineFilter } from "react-icons/md";
import {
  RiDragMove2Fill,
  RiFolderSharedLine,
  RiMoreFill,
} from "react-icons/ri";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useAppContext } from "../../../context";
import {
  ModalManager,
  ModalRenderer,
  NavBottomSheets,
  useAddBtnModalState,
} from "../../index";
import style from "./MobileCommands.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const MobileCommands = ({ sortable, setSortable }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { images, colormatch, setCreateNewPaletteModal } = useAppContext();
  const { closeModal, modalState, showModal } = useAddBtnModalState();
  const [showBottomSheet, setShowBottomSheet] = useState({
    add: false,
    more: false,
  });
  const [category, setCategory] = useState("");
  const [show, setShow] = useState({
    save: false,
    download: false,
    share: false,
  });

  const handleOptionClick = (optionKey, optionLabel) => {
    if (optionKey !== "balloon" || optionKey !== "custom") {
      showModal(optionKey);
      setCategory(optionLabel);
    } else {
      showModal(optionKey);
    }
  };

  const handleShowBottomSheet = (option) => {
    setShowBottomSheet((prevState) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, [modalState, show]);

  const handleCreateNewPalette = (e) => {
    const currentPath = location.pathname.split("/").slice(2).join("/"); // Obtener la ruta sin el prefijo de idioma
    const newPath = `/${i18n.language}/colormatch`;
    setShowBottomSheet({ more: false });

    if (currentPath !== "colormatch") {
      navigate(newPath);
    } else {
      setCreateNewPaletteModal(true);
    }
  };

  return (
    <>
      <Navbar fixed="bottom" className={style.mobileCommands}>
        <Nav justify className={style.navRow}>
          <Nav.Item>
            <Button
              onClick={handleCreateNewPalette}
              disabled={colormatch.length === 0 && images.length === 0}
              className={style.mobileCommandBtn}
            >
              <FiPlusCircle size="1.2rem" />
              New
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              onClick={() => handleShowBottomSheet("add")}
              className={style.mobileCommandBtn}
            >
              <MdOutlineFilter size="1.2rem" />
              Insert
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              onClick={() => setSortable(!sortable)}
              disabled={colormatch.length < 2}
              className={style.mobileCommandBtn}
            >
              {sortable ? (
                <MdDone size="1.5rem" />
              ) : (
                <RiDragMove2Fill size="1.5rem" />
              )}
              {sortable ? (
                <span>{t("Done")}</span>
              ) : (
                <span> {t("Reorder")}</span>
              )}
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              disabled={colormatch.length < 2}
              className={style.mobileCommandBtn}
              onClick={() => setShow({ save: true })}
            >
              <RiFolderSharedLine size="1.2rem" />
              Save
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              className={style.mobileCommandBtn}
              onClick={() => handleShowBottomSheet("more")}
            >
              <RiMoreFill size="1.5rem" />
              More
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar>
      {/* ------------------------------------------------------------- */}
      <NavBottomSheets.Add
        showBottomSheet={showBottomSheet.add}
        setShowBottomSheet={setShowBottomSheet}
        handleOptionClick={handleOptionClick}
      />
      <NavBottomSheets.More
        showBottomSheet={showBottomSheet.more}
        setShowBottomSheet={setShowBottomSheet}
        setShow={setShow}
      />
      {/* Add from library modals */}
      <ModalRenderer
        category={category}
        closeModal={closeModal}
        modalState={modalState}
      />
      {/* Save, Download and Share modals */}
      <ModalManager show={show} setShow={setShow} />
    </>
  );
};

export default MobileCommands;

import React, { useCallback, useEffect, useState } from "react";
import { ModalWindow } from "../../ModalWindow";
import { DownloadContent, SaveContent, ShareContent } from "../index";

const ModalManager = ({ show, setShow }) => {
  const [savedPalette, setSavedPalette] = useState(false);
  const [options, setOptions] = useState({
    image: false,
    export: null,
  });

  const handleHide = useCallback(
    (option) => {
      setShow((prevState) => ({
        ...prevState,
        [option]: false,
      }));
    },
    [setShow]
  );

  useEffect(() => {
    if (savedPalette) {
      handleHide("save");
    }
  }, [savedPalette, handleHide]);

  if (show.save) {
    return (
      <ModalWindow
        show={show.save}
        onHide={() => handleHide("save")}
        centered
        closeButton
        title="Save"
        body={<SaveContent setSavedPalette={setSavedPalette} />}
      />
    );
  } else if (show.download) {
    return (
      <ModalWindow
        show={show.download}
        onHide={() => handleHide("download")}
        centered
        closeButton
        title="Download"
        body={<DownloadContent window={show.download} />}
      />
    );
  } else if (show.share) {
    return (
      <ModalWindow
        show={show.share}
        onHide={() => handleHide("share")}
        centered
        closeButton
        title="Share"
        body={
          <ShareContent
            window={show.share}
            options={options}
            setOptions={setOptions}
          />
        }
      />
    );
  } else {
    return null;
  }
};

export default ModalManager;

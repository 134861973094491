import React, { Fragment, useState } from "react";
import { MdOutlineFilter } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { RiImageAddFill } from "react-icons/ri";
import { AiOutlineFolderOpen } from "react-icons/ai";
import { RiUploadCloudLine } from "react-icons/ri";
import { Dropdown } from "react-bootstrap";
import { useAppContext } from "../../context";
import {
  DropdownBtn,
  DropZone,
  ModalRenderer,
  useAddBtnModalState,
} from "../index";
import { addDropdownOptions, useScreenSize } from "../../utils";
import style from "./Add.module.css";

const AddButton = () => {
  const { images } = useAppContext();
  const { closeModal, modalState, showModal } = useAddBtnModalState();
  const [category, setCategory] = useState("");

  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const handleOptionClick = (optionKey, optionLabel) => {
    if (optionKey !== "balloon" || optionKey !== "custom") {
      showModal(optionKey);
      setCategory(optionLabel);
    } else {
      showModal(optionKey);
    }
  };

  return (
    <Fragment>
      <DropdownBtn
        drop={screenSize < 768 && "up"}
        variant="secondary"
        id="dropdown-basic"
        title={
          <>
            <MdOutlineFilter size={screenSize < 768 && "1.2rem"} /> {t("Add")}
            ...
          </>
        }
      >
        <Dropdown.Item
          disabled={images.length > 3 ? true : false}
          className={`${style.dropdownItem} d-flex align-items-center`}
        >
          <RiImageAddFill size={16} /> <DropZone addDropdown={true} />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          disabled
          className={`${style.dropdownItem} d-flex align-items-center`}
        >
          <AiOutlineFolderOpen size={16} />
          Add to palette from Library...
        </Dropdown.Item>
        {addDropdownOptions.map((option, index) => (
          <Dropdown.Item
            onClick={() => handleOptionClick(option.key, option.label)}
            key={index}
            className={`${
              option.label !== "Add your own" && style.dropdownItemNested
            } ${style.dropdownItem} d-flex align-items-center`}
          >
            {option.label === "Add your own" && <RiUploadCloudLine size={16} />}{" "}
            {t(option.label)}
          </Dropdown.Item>
        ))}
      </DropdownBtn>
      <ModalRenderer
        category={category}
        closeModal={closeModal}
        modalState={modalState}
      />
    </Fragment>
  );
};

export default AddButton;

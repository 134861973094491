import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { HiOutlineArrowsUpDown } from "react-icons/hi2";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import { DropdownBtn } from "../../components";
import { PaletteCard } from "./index";
import { usePalette } from "../../utils";
import style from "./Profile.module.css";

const MyPalettes = () => {
  const { user, setColormatch, setImages } = useAppContext();
  const [order, setOrder] = useState("desc");
  const { data, isLoading, isSuccess } = usePalette(user?.email, order);
  const { i18n } = useTranslation();
  const palettes = isSuccess
    ? [
        {
          addOne: (
            <div className={style.addBtn}>
              <FiPlus size="2rem" />
              Create New Palette
            </div>
          ),
        },
        ...data,
      ]
    : [];
  const navigate = useNavigate();

  const handleEditPalette = async (palette) => {
    // Función para convertir una URL de imagen a un objeto File
    const convertURLToFile = async (imageURL, imageName) => {
      const response = await fetch(imageURL);
      const blob = await response.blob();
      const file = new File([blob], imageName, { type: blob.type });
      return file;
    };

    // Convertir todas las imágenes en archivos "File"
    const imageFiles = await Promise.all(
      palette.images.map(async (image) => {
        const { imageURL, imageName } = image;
        return await convertURLToFile(imageURL, imageName);
      })
    );

    // Setear las imágenes y colores en el estado
    setColormatch(palette.colors);
    setImages(imageFiles);
    navigate(`/${i18n.language}/colormatch/${palette.id}`);
    /*  navigate(`/colormatch/${palette.id}`); */
  };

  const handleOrder = (e) => {
    const text = e.target.innerText;
    const newOrder = text === "Oldest" ? "asc" : "desc";
    setOrder(newOrder);
  };

  return (
    <Container>
      <Row className=" align-items-end justify-content-between my-4">
        <Col xs="auto">
          <h1>Saved palettes</h1>
        </Col>
        <Col xs="auto">
          <DropdownBtn
            variant="dark"
            id="dropdown-basic"
            className={style.actionBtn}
            title={
              <>
                <HiOutlineArrowsUpDown /> Sort by{" "}
                {order === "asc" ? "Oldest" : "Newest"}
              </>
            }
          >
            <Dropdown.Item onClick={handleOrder}>Oldest</Dropdown.Item>
            <Dropdown.Item onClick={handleOrder}>Newest</Dropdown.Item>
          </DropdownBtn>
          {/* Palettes Saved */}
        </Col>
      </Row>
      <Row>
        {!isLoading &&
          palettes?.map((palette, index) => (
            <PaletteCard
              key={index}
              palette={palette}
              handleEditPalette={handleEditPalette}
            />
          ))}
      </Row>
    </Container>
  );
};

export default MyPalettes;
